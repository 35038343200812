import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Login } from '@modelos/login';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public apiService: string = `${environment.urlSnape}/api/User`;
  private loggedIn = signal(false);
  userLogged = signal('');
  private msalService = inject(MsalService); 


  constructor(private http: HttpClient) { }

  userAuthenticate(data: Login) {
    return this.http.post(`${this.apiService}/login`, data, { responseType: 'text'}).pipe(
      tap(res => {
          if (res) {
              this.loggedIn.set(true);
          }
      }))
}


isLoggedIn(): boolean {
    return this.loggedIn();
}

setLogin(logado : boolean){
  this.loggedIn.set(logado);
}

checkUserLogged(): boolean {
  const usrLogged : string =  localStorage.getItem('UserLogged') ?? '';
  if ( usrLogged != '') {
    this.userLogged.set(usrLogged);
    this.loggedIn.set(true);
    return true;
  }
  return false;
}

checkTokenAndUserMsal() {
  // console.log(this.msalService.instance.getAllAccounts());

  const contas = this.msalService.instance.getAllAccounts();

  if (contas.length > 0) {
    const email = contas[0].username; 
    const idTokenClaims = contas[0].idTokenClaims;

    let roles: string[] = []; // Inicializa como array vazio

    if (idTokenClaims && idTokenClaims.roles && idTokenClaims.roles.length > 0) {
      roles = idTokenClaims.roles; // Armazena todas as roles
    }

    // console.log('Usuário:', email);
    // console.log('Roles:', roles); // Mostra todas as roles no console

    localStorage.setItem('UserLogged', email); // Armazena no localStorage
    localStorage.setItem('UserRoles', JSON.stringify(roles)); // Armazena as roles
    this.userLogged.set(email); // Atualiza o Signal
    this.loggedIn.set(true);
  }
}


}
